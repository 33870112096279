import { Component, Inject, Input, OnInit } from '@angular/core';
import {MatomoService} from '../../../../core/services/matomo.service';
import {AdsRestService} from '../../../../core/services/api/cms/ads.rest-service';

@Component({
  selector: 'phx-ad-report',
  templateUrl: './ad-report.component.html',
  styleUrls: ['./ad-report.component.scss',
      '../supplier-report.component.scss']
})
export class AdReportComponent implements OnInit {
  @Input() ad;
  @Input() idfCount = 1;
  click: number = 0;
  view: number = 0;
  group: number = 0;
  viewPerc: number = 0;
  adsForDate: any[] = [];
  resolutions = {};
  impressions: any = null;
  compares: any;
  days= 0;
  selectedImpressions: any;
  locations = [
    {value: 'ALL', viewValue: 'Alle'},
    {value: 'DASH', viewValue: 'Super Banner'},
    {value: 'RIGHT', viewValue: 'Rechts Skyscraper Banner'},
    {value: 'LEFT', viewValue: 'Links Skyscraper Banner'},
    {value: 'MENU', viewValue: 'Square Banner'},
    {value: 'PROMO', viewValue: 'Promotions Banner'}
  ];

  constructor(private matomoService: MatomoService,
              private adsService: AdsRestService,
              @Inject('CDN') public CDN) { }

  ngOnInit(): void {
    this.days = this.matomoService.getDateRange(new Date(this.ad.start), new Date(this.ad.end));

    this.getData();
  }

  private getData() {
    const startDate = new Date(this.ad.start);
    const startDateThree = new Date(startDate.setMonth(startDate.getMonth()-3));
    const endDate = new Date(this.ad.end);
    this.matomoService.getResolutions(startDateThree, endDate).subscribe(res => {
      this.resolutions = res;
    });
    this.matomoService.getImpressions(startDateThree, endDate).subscribe(impress => {
      this.impressions = impress;
      this.selectedImpressions = impress.find(i => i.label.indexOf(this.ad.image) > -1);
    });

    this.matomoService.searchForRange('Klick+auf+Ads+Banner', startDateThree, endDate).subscribe(ads => {
      ads.subscribe(adsDate => {
        this.adsForDate = adsDate;
        this.calculateClickPerformance();
        this.calculateTarget();
        this.calculateVisibility();
      });
    });

    this.adsService.getForDate(startDateThree.toISOString().split('T')[0], this.ad.end).subscribe(ads => {
      this.compares = this.matomoService.getComparableAds(this.ad, ads.return_object);
    });
  }

  calculateClickPerformance() {
    if (this.adsForDate.length > 0 && this.compares) {
      const title = this.ad.title.replace('+', ' ').trim();
      const selected = this.adsForDate.find(ad => ad.label.trim() === title);
      let compareReport = [];

      this.adsForDate.forEach(ad => {
        if(ad.label !== this.ad.title && this.compares.find((compare: any) => compare.title.trim() === ad.label.trim()) !== undefined) {
          compareReport.push(ad);
        }
      });
      const selectedClicks = this.matomoService.calculateClicksPerDay(selected, this.days);

      let compareClicks = 0;
      let compareNr = 0;

      for(let compare of this.compares){
        let compareDays = 0;
        let title = compare.title.replace('+', ' ').trim()
        compareDays = this.matomoService.getDateRange(new Date(compare.start), new Date(compare.end));
        let comp = compareReport.find(comp => comp.label.trim() === title);

        if(comp) {
          compareClicks += this.matomoService.calculateClicksPerDay(comp, compareDays);
          compareNr++;
        }
      }

      this.click = +((selectedClicks / (compareClicks/compareNr)).toFixed(2));
    }
  }

  calculateTarget(){
    const idfs = this.ad.idfs.length;
    const selected = this.adsForDate.filter(ad => ad.label === this.ad.title.replace('+', ' '))[0];

    if(idfs === 0){
      this.group = 0;
    }
    else {
      this.group = +(this.matomoService.calculateTargetPerformance(idfs, this.selectedImpressions).toFixed(2));
    }
  }

  calculateVisibility() {
    const selectedVis = this.matomoService.calculateAdVisibility(this.ad, this.selectedImpressions, this.resolutions, this.compares, this.idfCount);
    let compareVis = 0;

    for (let compare of this.compares) {
      let impress = {};
      impress = this.impressions.find(i => i.label.indexOf(compare.image) > -1)

      let comps = this.matomoService.getComparableAds(compare, this.compares);
      compareVis += this.matomoService.calculateAdVisibility(compare, impress, this.resolutions, comps, this.idfCount);
    }

    this.view = +(selectedVis).toFixed(2);

    //same perc like the first one 0-200%
    this.viewPerc = +((selectedVis / compareVis).toFixed(2));
  }

  getDate(date) {
    return new Date(date);
  }

  getAdLocation(loc) {
    return this.locations.find(x => x.value === loc )?.viewValue;
  }
}
