<div id="content">
    <div class="panel">
        <h2>Banner Ads</h2>
        <div class="row" *ngIf="!loadingAds">
            <form [formGroup]="dataForm" class="col-md-12">
                <mat-form-field appearance="outline" class="col-md-6">
                    <mat-label>Ads</mat-label>
                    <mat-select placeholder="Select Ad" formControlName="selectedAds" multiple>
                        <mat-option *ngFor="let target of adsForDate"
                                    [value]="target.id">
                            {{ target.title }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-md-6">
                    <mat-label>Slider</mat-label>
                    <mat-select placeholder="Select Slide" formControlName="selectedSlide" multiple>
                        <mat-option *ngFor="let target of sliders"
                                    [value]="target.title">
                            {{ target.title }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-md-6">
                    <mat-label>Advertorial</mat-label>
                    <mat-select placeholder="Select Advertorial" formControlName="selectedAdvertorial">
                        <mat-option *ngFor="let target of adverts"
                                    [value]="target.id">
                            {{ target.title }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-md-6">
                    <mat-label>Registered Idfs</mat-label>
                    <input matInput formControlName="idfCount" />
                </mat-form-field>
            </form>
        </div>
        <div class="row">
            <div class="col-md-6">
                <button class="btn btn-primary" (click)="getData()">Generate</button>
            </div>
            <div class="col-md-6 text-right">
                <button class="btn btn-secondary" (click)="exportPdf()" [disabled]="!added">Export Report</button>
            </div>
        </div>
    </div>
    <mat-tab-group class="panel" #tabs>
        <mat-tab label="Ad Report">
            <button class="btn btn-primary" (click)="addToFile('ads')">Add To File</button>
            <div *ngFor="let selectedAd of selectedAds" class="mt-l">
                <phx-ad-report [ad]="selectedAd" class="ads" [idfCount]="dataForm.get('idfCount').value"></phx-ad-report>
            </div>
        </mat-tab>
        <mat-tab label="Slider Report">
            <button class="btn btn-primary" (click)="addToFile('slides')">Add To File</button>
                <div class="slider mt-l" *ngFor="let selectedSlide of selectedSlides">
                    <phx-slider-report [slide]="selectedSlide"></phx-slider-report>
                </div>
        </mat-tab>
        <mat-tab label="Advertorial Report">
            <button class="btn btn-primary" (click)="addToFile('adverts')">Add To File</button>
                <div class="advert mt-l">
                    <phx-advertorial-report [advertorial]="selectedAdvert" *ngIf="selectedAdvert"></phx-advertorial-report>
                </div>
        </mat-tab>
        <mat-tab label="Dialog Popup Report">
            <button class="btn btn-primary" (click)="addToFile('popups')">Add To File</button>
                <div class="popup mt-l">
                    To be added
                </div>
        </mat-tab>
    </mat-tab-group>
</div>
