import {map} from 'rxjs/operators';
import {Inject, Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs';
import {Response} from '../../models/response.model';
import {RestServiceAbstract} from '../../abstracts/rest-service.abstract';
import {ResponseSnakeModel} from '../../models/response-snake.model';

@Injectable({
    providedIn: 'root',
})
export class MatomoRestService extends RestServiceAbstract implements OnDestroy {
    protected ngUnsubscribe$: Subject<void> = new Subject<void>();


//?module=API&method=API.getBulkRequest&format=json&urls[0]=method%3dVisitsSummary.get%26idSite%3d3%26date%3d2012-03-06%26period%3dday&urls[1]=method%3dVisitorInterest.getNumberOfVisitsPerVisitDuration%26idSite%3d3%26date%3d2012-03-06%26period%3dday

    getDataForPeriod(period, date) {
        return this.post('/matomo/report', {
            method: 'Events.getActionFromCategoryId',
            id_site: 3,
            period: period,
            date: date.start + ',' + date.end,
            id_subtable: date.id_subtable
        }).pipe(
            map(data => {
                return new ResponseSnakeModel(data).return_object;
            })
        );
    }

    getDataForRange(data) {
        return this.post('/matomo/report/range', {
            method: 'Events.getActionFromCategoryId',
            id_site: 3,
            period: 'range',
            date: data.start + ',' + data.end,
            id_subtable: data.id_subtable,
            label: null
        }).pipe(
            map(data => {
                return new ResponseSnakeModel(data).return_object;
            })
        );
    }

    getCategoryForRange(data) {
        return this.post('/matomo/report/range', {
            method: 'Events.getCategory',
            id_site: 3,
            period: 'range',
            date: data.start + ',' + data.end,
            label: data.label,
            id_subtable: null
        }).pipe(
            map(data => {
                return new ResponseSnakeModel(data).return_object;
            })
        );
    }

    getResolutions(data) {
        return this.post('/matomo/report/range', {
            method: 'Resolution.getResolution',
            id_site: 3,
            period: 'range',
            date: data.start + ',' + data.end,
            label: null,
            id_subtable: null
        }).pipe(
            map(data => {
                return new ResponseSnakeModel(data).return_object;
            })
        );
    }


    getImpressionsForRange(search) {
        return this.post('/matomo/report/impressions', {
            method: 'Contents.getContentPieces',
            id_site: 3,
            period: 'range',
            date: search.start + ',' + search.end
        }).pipe(
            map(data => {
                return new ResponseSnakeModel(data).return_object;
            })
        );
    }


        /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        // This aborts all HTTP requests.
        this.ngUnsubscribe$.next();
        // This completes the subject properly.
        this.ngUnsubscribe$.complete();
    }
}