import {NavItem} from './nav-item.model';

export class Nav {
    headline = '';
    component = '';
    tree: NavItem[];

    constructor(obj: any = {}) {
        this.headline = obj && obj.headline || 'PHOENIX Admin App';
        this.component = obj && obj.component || '';
        this.tree = obj && obj.tree || [];

        if (this.tree.length === 0) {
            this.tree = [];
            this.tree.push(new NavItem({navLinkName: 'Dashboard', path: 'dashboard'}));

            /*            this.tree.push(new NavItem({
                            navLinkName: 'Authorities',
                            tree: [
                                new NavItem({
                                    navLinkName: 'Verwalten',
                                    heading: 'Verwalten',
                                    component: 'authorities-verwalten',
                                    path: 'authorities-verwalten',
                                    isTile: true,
                                    tileTitle: 'Authorities verwalten',
                                    tileImageFileName: 'assets/img/tiles/svg/konto-kontaktdaten-kachel.svg',
                                    tileImageFileNameHover: 'assets/img/tiles/svg/konto-kontaktdaten-hover-kachel.svg',
                                }),
                                new NavItem({
                                    navLinkName: 'Zuordnen',
                                    heading: 'Zuordnen',
                                    component: 'authorities-zuordnen',
                                    path: 'authorities-zuordnen',
                                    isTile: true,
                                    tileTitle: 'Authorities zuordnen',
                                    tileImageFileName: 'assets/img/tiles/svg/konto-kontaktdaten-kachel.svg',
                                    tileImageFileNameHover: 'assets/img/tiles/svg/konto-kontaktdaten-hover-kachel.svg',
                                }),
                            ]
                        }));*/
            this.tree.push(new NavItem({
                navLinkName: 'Marketing (BETA)',
                heading: 'Marketing management',
                requiredAuthority: ['MARKETING'],
                tree: [
                    new NavItem({
                        heading: 'Slider',
                        navLinkName: 'Slider',
                        component: 'slider-verwalten',
                        path: 'slider-verwalten',
                        isTile: true,
                        tileTitle: 'Slider',
                        tileImageFileName: 'assets/img/tiles/svg/slider-kachel.svg',
                        tileImageFileNameHover: 'assets/img/tiles/svg/slider-hover-kachel.svg',
                        requiredAuthority: ['MARKETING']
                    }),
                    new NavItem({
                        heading: 'Banner Ads',
                        navLinkName: 'Banner Ads',
                        component: 'ads-verwalten',
                        path: 'ads-verwalten',
                        isTile: true,
                        tileTitle: 'Banner Ads',
                        tileImageFileName: 'assets/img/tiles/svg/ads-kachel.svg',
                        tileImageFileNameHover: 'assets/img/tiles/svg/ads-hover-kachel.svg',
                        requiredAuthority: ['MARKETING']

                    }),
                    new NavItem({
                        heading: 'Advertorials',
                        navLinkName: 'Advertorials',
                        component: 'advertorial-verwalten',
                        path: 'advertorial-verwalten',
                        isTile: true,
                        tileTitle: 'Advertorials',
                        tileImageFileName: 'assets/img/tiles/svg/ads-kachel.svg',
                        tileImageFileNameHover: 'assets/img/tiles/svg/ads-hover-kachel.svg',
                        requiredAuthority: ['MARKETING']
                }),

                    new NavItem({
                        heading: 'Popup',
                        navLinkName: 'Popup',
                        component: 'popup-verwalten',
                        path: 'popup-verwalten',
                        isTile: true,
                        tileTitle: 'Popup',
                        tileImageFileName: 'assets/img/tiles/svg/ads-kachel.svg',
                        tileImageFileNameHover: 'assets/img/tiles/svg/ads-hover-kachel.svg'

                    })
                ]
            }));
            this.tree.push(new NavItem({
                navLinkName: 'Content (BETA)',
                heading: 'Content management',
                requiredAuthority: ['CMS'],
                tree: [
                    new NavItem({
                        heading: 'Pages',
                        navLinkName: 'Pages management',
                        path: 'pages-management',
                        component: 'pages-management',
                        isTile: true,
                        tileTitle: 'Pages management',
                        tileImageFileName: 'assets/img/tiles/svg/pages.svg',
                        tileImageFileNameHover: 'assets/img/tiles/svg/pages-hover.svg',
                        requiredAuthority: ['CMS']

                    }),
                    new NavItem({
                        heading: 'Forms',
                        navLinkName: 'Forms management',
                        path: 'forms-management',
                        component: 'forms-management',
                        isTile: true,
                        tileTitle: 'Forms management',
                        tileImageFileName: 'assets/img/tiles/svg/forms.svg',
                        tileImageFileNameHover: 'assets/img/tiles/svg/forms-hover.svg',
                        requiredAuthority: ['CMS']
                    }),
                    new NavItem({
                        heading: 'Files',
                        navLinkName: 'Files management',
                        component: 'files-management',
                        path: 'files-management',
                        isTile: true,
                        tileTitle: 'Files management',
                        tileImageFileName: 'assets/img/tiles/svg/files.svg',
                        tileImageFileNameHover: 'assets/img/tiles/svg/files-hover.svg',
                        requiredAuthority: ['CMS']



                    })

                ]
            }));
            this.tree.push(new NavItem({
                navLinkName: 'Matomo',
                heading: 'Matomo',
                requiredAuthority: ['SUPPLIER_REPORT'],
                tree: [
                    new NavItem({
                        heading: 'Hersteller Report',
                        navLinkName: 'Hersteller Report',
                        component: 'supplier-report',
                        path: 'supplier-report',
                        isTile: true,
                        tileTitle: 'Hersteller Report',
                        tileImageFileName: 'assets/img/tiles/svg/supplier-report.svg',
                        tileImageFileNameHover: 'assets/img/tiles/svg/supplier-report-hover.svg',
                        requiredAuthority: ['SUPPLIER_REPORT']
                    })
                ]
            }));
            this.tree.push(new NavItem({
                navLinkName: 'Schnittstellen',
                heading: 'Schnittstellen',
                requiredAuthority: ['INTERFACES'],
                tree: [
                    new NavItem({
                        heading: 'Datev',
                        navLinkName: 'Datev',
                        component: 'datev-verwalten',
                        path: 'datev-verwalten',
                        isTile: true,
                        tileTitle: 'Datev',
                        tileImageFileName: 'assets/img/tiles/svg/datev-kachel.svg',
                        tileImageFileNameHover: 'assets/img/tiles/svg/datev-hover-kachel.svg',
                        requiredAuthority: ['INTERFACES']
                    })
                ]
            }));
            this.tree.push(new NavItem({
                navLinkName: 'Kommunikation',
                heading: 'Apothekenportal Nachrichten',
                dataCy: 'outbox',
                requiredAuthority: ['MESSAGES', 'DELAY_NOTIFICATIONS', 'NOTIFICATIONS'],
                tree: [
                    new NavItem({
                        heading: 'Nachricht erstellen',
                        navLinkName: 'Nachricht erstellen',
                        component: 'nachricht-erstellen',
                        path: 'nachricht-erstellen',
                        isTile: true,
                        tileTitle: 'Nachricht erstellen',
                        tileImageFileName: 'assets/img/tiles/svg/offizin-im-blick-kachel.svg',
                        tileImageFileNameHover: 'assets/img/tiles/svg/offizin-im-blick-hover-kachel.svg',
                        dataCy: 'outbox-draft',
                        requiredAuthority: ['MESSAGES']
                    }),
                    new NavItem({
                        heading: 'Gesendete Nachrichten',
                        navLinkName: 'Gesendete Nachrichten',
                        component: 'gesendete-nachrichten',
                        path: 'gesendete-nachrichten',
                        isTile: true,
                        tileTitle: 'Gesendete Nachrichten',
                        tileImageFileName: 'assets/img/tiles/svg/offizin-im-blick-kachel.svg',
                        tileImageFileNameHover: 'assets/img/tiles/svg/offizin-im-blick-hover-kachel.svg',
                        dataCy: 'outbox-sent',
                        requiredAuthority: ['MESSAGES']

                    }),
                    new NavItem({
                        heading: 'Portalbenachrichtigungen',
                        navLinkName: 'Portalbenachrichtigungen',
                        component: 'notifications',
                        path: 'notifications',
                        isTile: true,
                        tileTitle: ' Portalbenachrichtigungen',
                        tileImageFileName: 'assets/img/tiles/svg/notices-kachel.svg',
                        tileImageFileNameHover: 'assets/img/tiles/svg/notices-hover-kachel.svg',
                        requiredAuthority: ['NOTIFICATIONS']
                    }),
                    new NavItem({
                        heading: 'Lieferverzögerungen',
                        navLinkName: 'Lieferverzögerungen',
                        component: 'delay-info',
                        path: 'delay-info',
                        isTile: true,
                        tileTitle: 'Lieferverzögerungen',
                        tileImageFileName: 'assets/img/tiles/svg/track-and-trace.svg',
                        tileImageFileNameHover: 'assets/img/tiles/svg/track-and-trace-hover.svg',
                        requiredAuthority: ['DELAY_NOTIFICATIONS'],
                        // dataCy: 'outbox-sent'
                    }),
                ]
            }));
            this.tree.push(new NavItem({
                navLinkName: 'Zugänge',
                requiredAuthority: ['ADMINISTRATION', 'PHARMACY_USERS', 'PHARMACIES'],
                tree: [
                    new NavItem({
                        heading: 'Benutzerkonten PHOENIX',
                        navLinkName: 'Benutzerkonten PHOENIX',
                        component: 'phoenix-mitarbeitern',
                        path: 'phoenix-mitarbeitern',
                        isTile: true,
                        tileTitle: 'Benutzerkonten PHOENIX',
                        tileImageFileName: 'assets/img/tiles/svg/apothekenstammdaten-kachel.svg',
                        tileImageFileNameHover: 'assets/img/tiles/svg/apothekenstammdaten-hover-kachel.svg',
                        requiredAuthority: ['ADMINISTRATION']
                    }),
                    new NavItem({
                        heading: 'Benutzerkonten Apotheken',
                        navLinkName: 'Benutzerkonten Apotheken',
                        component: 'apotheken-benutzer',
                        path: 'apotheken-benutzer',
                        isTile: true,
                        tileTitle: 'Benutzerkonten Apotheken',
                        tileImageFileName: 'assets/img/tiles/svg/apothekenstammdaten-kachel.svg',
                        tileImageFileNameHover: 'assets/img/tiles/svg/apothekenstammdaten-hover-kachel.svg',
                        requiredAuthority: ['PHARMACY_USERS']
                    }),
                    new NavItem({
                        heading: 'Apotheken',
                        navLinkName: 'Apotheken',
                        component: 'account-management',
                        path: 'account-management',
                        isTile: true,
                        tileTitle: 'Apotheken',
                        tileImageFileName: 'assets/img/tiles/svg/apothekenstammdaten-kachel.svg',
                        tileImageFileNameHover: 'assets/img/tiles/svg/apothekenstammdaten-hover-kachel.svg',
                        requiredAuthority: ['PHARMACIES']
                    }),
                    new NavItem({
                        heading: 'Benutzerkonten Berater',
                        navLinkName: 'Berater',
                        component: 'berater-benutzer',
                        path: 'berater-benutzer',
                        isTile: true,
                        tileTitle: 'Berater',
                        tileImageFileName: 'assets/img/tiles/svg/apothekenstammdaten-kachel.svg',
                        tileImageFileNameHover: 'assets/img/tiles/svg/apothekenstammdaten-hover-kachel.svg',
                        requiredAuthority: ['PHARMACIES']
                    })
                ]
            }));
            this.tree.push(new NavItem({
                navLinkName: 'PAYBACK',
                requiredAuthority: ['GROUP_ADMIN'],
                tree: [
                    new NavItem({
                        heading: 'Produkte',
                        navLinkName: 'Produkte',
                        component: 'payback-produkte',
                        path: 'payback-produkte',
                        isTile: false,
                        tileTitle: 'Produkte',
                        tileImageFileName: 'assets/img/tiles/svg/payback-kachel.svg',
                        tileImageFileNameHover: 'assets/img/tiles/svg/payback-hover-kachel.svg',
                        requiredAuthority: ['GROUP_ADMIN']
                    }),
                    /*   new NavItem({
                           heading: 'Werbemittellayouts',
                           navLinkName: 'Werbemittellayouts',
                           component: 'layouts',
                           path: 'layouts',
                           isTile: true,
                           tileTitle: 'Werbemittellayouts',
                           tileImageFileName: 'assets/img/tiles/svg/payback-kachel.svg',
                           tileImageFileNameHover: 'assets/img/tiles/svg/payback-hover-kachel.svg',
                       }),
                       new NavItem({
                           heading: 'Werbemittelthemen',
                           navLinkName: 'werbemittelthemen',
                           component: 'themen',
                           path: 'themen',
                           isTile: true,
                           tileTitle: 'Werbemittelthemen',
                           tileImageFileName: 'assets/img/tiles/svg/payback-kachel.svg',
                           tileImageFileNameHover: 'assets/img/tiles/svg/payback-hover-kachel.svg',
                       }),
                       new NavItem({
                           heading: 'Werbemittelvorlagen',
                           navLinkName: 'Werbemittelvorlagen',
                           component: 'vorlagen',
                           path: 'vorlagen',
                           isTile: true,
                           tileTitle: 'Werbemittelvorlagen',
                           tileImageFileName: 'assets/img/tiles/svg/payback-kachel.svg',
                           tileImageFileNameHover: 'assets/img/tiles/svg/payback-hover-kachel.svg',
                       }),*/
                ]
            }));
            this.tree.push(new NavItem({
                navLinkName: 'Dokumente',
                requiredAuthority: ['GROUP_ADMIN'],
                tree: [
                    new NavItem({
                        heading: '\n' +
                            'Dokumentenkonfiguration',
                        navLinkName: '\n' +
                            'Dokumentenkonfiguration',
                        component: 'documents-config',
                        path: 'documents-config',
                        isTile: false,
                        tileTitle: '\n' +
                            'Dokumentenkonfiguration',
                        tileImageFileName: 'assets/img/tiles/svg/apothekenstammdaten-kachel.svg',
                        tileImageFileNameHover: 'assets/img/tiles/svg/apothekenstammdaten-hover-kachel.svg',
                        requiredAuthority: ['GROUP_ADMIN']
                    }),
                    new NavItem({
                        heading: 'Rechnungsbeilagen',
                        navLinkName: 'Rechnungsbeilagen',
                        component: 'rechnungs-beilagen',
                        path: 'rechnungs-beilagen',
                        isTile: false,
                        tileTitle: 'Rechnungsbeilagen',
                        tileImageFileName: 'assets/img/tiles/svg/apothekenstammdaten-kachel.svg',
                        tileImageFileNameHover: 'assets/img/tiles/svg/apothekenstammdaten-hover-kachel.svg',
                        requiredAuthority: ['GROUP_ADMIN']
                    })
                ]
            }));
            this.tree.push(new NavItem({
                navLinkName: 'Synchronizer',
                requiredAuthority: ['GROUP_ADMIN'],
                tree: [
                    new NavItem({
                        heading: '\n' +
                            'Import Übersicht',
                        navLinkName: '\n' +
                            'Import Übersicht',
                        component: 'synchronizer-overview',
                        path: 'synchronizer-overview',
                        isTile: false,
                        tileTitle: '\n' +
                            'Import Übersicht',
                        tileImageFileName: 'assets/img/tiles/svg/apothekenstammdaten-kachel.svg',
                        tileImageFileNameHover: 'assets/img/tiles/svg/apothekenstammdaten-hover-kachel.svg',
                        requiredAuthority: ['GROUP_ADMIN']
                    })
                ]
            }));
            this.tree.push(new NavItem({
                navLinkName: 'Promotions',
                requiredAuthority: ['PROMOTIONS'],
                tree: [
                    new NavItem({
                        heading: 'Promotions Übersicht',
                        navLinkName: 'Promotions Übersicht',
                        component: 'promotions-overview',
                        path: 'promotions-overview',
                        isTile: true,
                        tileTitle: 'Promotions Übersicht',
                        tileImageFileName: 'assets/img/tiles/svg/promotions-kachel.svg',
                        tileImageFileNameHover: 'assets/img/tiles/svg/promotions-hover-kachel.svg',
                        requiredAuthority: ['PROMOTIONS']
                    }),
                    new NavItem({
                        heading: 'Products',
                        navLinkName: 'Products',
                        component: 'promotion-products',
                        path: 'promotion-products',
                        isTile: true,
                        tileTitle: 'Products',
                        tileImageFileName: 'assets/img/tiles/svg/promotions-kachel.svg',
                        tileImageFileNameHover: 'assets/img/tiles/svg/promotions-hover-kachel.svg',
                        requiredAuthority: ['PROMOTIONS']
                    }),
                ]
            }));
            this.tree.push(new NavItem({
                navLinkName: 'Vendors',
                requiredAuthority: ['VENDORS'],
                tree: [
                    new NavItem({
                        heading: 'Vendors Übersicht',
                        navLinkName: 'Vendors Übersicht',
                        component: 'vendors-overview',
                        path: 'vendors-overview',
                        isTile: true,
                        tileTitle: 'Vendors Übersicht',
                        tileImageFileName: 'assets/img/tiles/svg/promotions-kachel.svg',
                        tileImageFileNameHover: 'assets/img/tiles/svg/promotions-hover-kachel.svg',
                        requiredAuthority: ['VENDORS']
                    })
                ]
            }));
            this.tree.push(new NavItem({
                navLinkName: 'Vaccines',
                requiredAuthority: ['VACCINES'],
                tree: [
                    new NavItem({
                        heading: 'Products',
                        navLinkName: 'Products',
                        component: 'vaccine-management',
                        path: 'vaccine-management',
                        isTile: false,
                        tileTitle: 'Products',
                        tileImageFileName: 'assets/img/tiles/svg/syringe-kachel.svg',
                        tileImageFileNameHover: 'assets/img/tiles/svg/syringe-hover-kachel.svg',
                        requiredAuthority: ['VACCINES']
                    }),
                    new NavItem({
                        heading: 'Zusatzbestellung COVID-19',
                        navLinkName: 'Zusatzbestellung COVID-19',
                        component: 'vaccine-shop-config',
                        path: 'vaccine-shop-config',
                        isTile: true,
                        tileTitle: 'Zusatzbestellung COVID-19',
                        tileImageFileName: 'assets/img/tiles/svg/vaccine-conf-kachel.svg',
                        tileImageFileNameHover: 'assets/img/tiles/svg/vaccine-conf-hover-kachel.svg',
                        requiredAuthority: ['VACCINES']
                    }),
                    new NavItem({
                        heading: 'Zuteilungsmenge COVID-19',
                        navLinkName: 'Zuteilungsmenge COVID-19',
                        component: 'vaccine-shipments-upload',
                        path: 'vaccine-shipments-upload',
                        isTile: true,
                        tileTitle: 'Zuteilungsmenge COVID-19',
                        tileImageFileName: 'assets/img/tiles/svg/syringe-kachel.svg',
                        tileImageFileNameHover: 'assets/img/tiles/svg/syringe-hover-kachel.svg',
                        requiredAuthority: ['VACCINES']
                    })
                ]
            }));
            this.tree.push(new NavItem({
                navLinkName: 'Preorders',
                requiredAuthority: ['PREORDERS'],
                tree: [
                    new NavItem({
                        heading: 'Preorder Categories',
                        navLinkName: 'Categories',
                        component: 'preorder-management',
                        path: 'preorder-management',
                        isTile: true,
                        tileTitle: 'Categories',
                        tileImageFileName: 'assets/img/tiles/svg/preorder-kachel.svg',
                        tileImageFileNameHover: 'assets/img/tiles/svg/preorder-hover-kachel.svg',
                        requiredAuthority: ['PREORDERS']
                    }),
                    new NavItem({
                        heading: 'Preorders Übersicht',
                        navLinkName: 'Preorders Übersicht',
                        component: 'preorders-view',
                        path: 'preorders-view',
                        isTile: true,
                        tileTitle: 'Preorders Übersicht',
                        tileImageFileName: 'assets/img/tiles/svg/preorder-kachel.svg',
                        tileImageFileNameHover: 'assets/img/tiles/svg/preorder-hover-kachel.svg',
                        requiredAuthority: ['PREORDERS']
                    }),
                ]
            }));
        }
    }

    getProperty(currentlyActivePath: string, property: string = 'heading'): string | boolean {
        if (!this.tree || currentlyActivePath === 'dashboard') {
            return false;
        }
        let propertyValue: string | boolean = false;
        this.tree.forEach(navItem => {
            if (navItem.path === currentlyActivePath || navItem.activatesPath.indexOf(currentlyActivePath) !== -1) {
                propertyValue = navItem.hasOwnProperty(property) ? navItem[property] : false;
            } else if (navItem.tree) {
                navItem.tree.forEach(subNavItem => {
                    if (subNavItem.path === currentlyActivePath || subNavItem.activatesPath.indexOf(currentlyActivePath) !== -1) {
                        propertyValue = subNavItem.hasOwnProperty(property) ? subNavItem[property] : false;
                    }
                });
            }
        });
        return propertyValue;
    }


    getParent(currentlyActivePath: string): string {
        if (!this.tree || currentlyActivePath === 'dashboard') {
            return null;
        }
        let ret = null;
        let done = false;

        this.tree.forEach(navItem => {
            if (navItem.tree) {
                navItem.tree.forEach(subNavItem => {
                    if (subNavItem.path === currentlyActivePath || subNavItem.activatesPath.indexOf(currentlyActivePath) !== -1) {
                        ret = navItem.navLinkName;
                        done = true;
                        return;
                    }
                });
            }
            if (done) {
                return;
            }
        });
        return ret;
    }

    // getProperty(currentlyActivePath: string, property: string = 'heading'): string | boolean {
    //     if (!this.tree || currentlyActivePath === 'dashboard') {
    //         return false;
    //     }
    //     let ret: string | boolean = false;
    //     let done = false;
    //
    //     this.tree.forEach(navItem => {
    //         if ((navItem.path === currentlyActivePath || navItem.activatesPath.indexOf(currentlyActivePath) !== -1)
    //             && navItem.hasOwnProperty(property)) {
    //             ret = navItem[property];
    //             done = true;
    //         } else if (navItem.tree) {
    //             navItem.tree.forEach(subNavItem => {
    //                 if ((subNavItem.path === currentlyActivePath || subNavItem.activatesPath.indexOf(currentlyActivePath) !== -1)
    //                     && subNavItem.hasOwnProperty(property)) {
    //                     ret = subNavItem[property];
    //                     done = true;
    //                     return;
    //                 }
    //             });
    //         }
    //         if (done) {
    //             return;
    //         }
    //     });
    //
    //     return ret;
    // }
}
