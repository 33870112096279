import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatomoReportsComponent} from './matomo-reports.component';
import {SupplierReportModule} from './supplier-report/supplier-report.module';
import {RouterModule} from '@angular/router';


export const routes = [
  {path: '', component: MatomoReportsComponent, pathMatch: 'full'},
];
@NgModule({
  declarations: [
      MatomoReportsComponent
  ],
  imports: [
    CommonModule,
    SupplierReportModule,
    RouterModule.forChild(routes)
  ]
})
export class MatomoReportsModule { }
