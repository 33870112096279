import * as fromReducers from '../store/reducers';
import {Injectable, OnDestroy} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {MatomoRestService} from './api/matomo.rest-service';

@Injectable({
    providedIn: 'root',
})
export class MatomoService implements OnDestroy{

    private ngUnsubscribe$ = new Subject<any>(); // emit unsubscription

    constructor(private matomoRestService: MatomoRestService){

    }

    calculateAdVisibility(ad, impressions, resolutions, compares, idfs) {
        let l = 1;
        let u = idfs;
        if (ad.idfs.length > 0) {
            u = ad.idfs.length;
        }
        const i = impressions.nb_visits;
        let n = 0;

        if (ad.location === 'RIGHT') {
            l = resolutions.right / resolutions.total;
        }

        n = this.getParallelAds(ad, compares).length;

        n++;

        return ((n * i) / (u * l));
    }

    calculateClicksPerDay(selected, days) {
        return parseInt(selected.nb_events) / days;
    }

    calculateTargetPerformance(no, impressions) {
        return no/impressions.nb_visits;
    }

    getResolutions(start, end) {
        return this.matomoRestService.getResolutions({
            start: start.toISOString().split('T')[0],
            end: end.toISOString().split('T')[0]
        }).pipe(takeUntil(this.ngUnsubscribe$),
            map(data => {
            let resultions = {total: 0, left: 0, right: 0};

            data.forEach(resolution => {
                resultions.total += parseInt(resolution.nb_visits);
                resultions.left += parseInt(resolution.nb_visits);

                const res = resolution.label.split('x');

                if (res[0] > 1460) {
                   resultions.right += parseInt(resolution.nb_visits);
                }
            });
            return resultions;
        }));
    }

    searchForRange(category, start, end) {
        return this.matomoRestService.getCategoryForRange({
            start: start.toISOString().split('T')[0],
            end: end.toISOString().split('T')[0],
            label: category
        }).pipe(takeUntil(this.ngUnsubscribe$),
            map((result: any) => {
                console.log(result);
            return this.matomoRestService.getDataForRange({
                start: start.toISOString().split('T')[0],
                end: end.toISOString().split('T')[0],
                id_subtable: result[0].idsubdatatable
            })
                .pipe(takeUntil(this.ngUnsubscribe$), map(data => {
                return data;
            }));
        }));
    }


    getImpressions(start, end) {
       return this.matomoRestService.getImpressionsForRange({
            start: start.toISOString().split('T')[0],
            end: end.toISOString().split('T')[0]
        }).pipe(takeUntil(this.ngUnsubscribe$),
           map(data => {
               console.log(data);
            return data;
        }));
    }

    getDateRange(start,end) {
        const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds

        return Math.round(Math.abs((start - end) / oneDay));
    }

    getParallelAds(selected, ads) {
        if(!ads) return [];
        return  ads.filter(ad => (ad.id !== selected.id && ad.location === selected.location && ((ad.idfs.length > 0) === (selected.idfs.length > 0)) && (ad.start >= selected.start && ad.end <= selected.end)));
    }

    getComparableAds(selected, ads) {
        if(!ads) return [];
        const parallel = this.getParallelAds(selected, ads);

        if(parallel.length > 0){
            return parallel;
        }
        return ads.filter(ad => (ad.id !== selected.id && ad.location === selected.location && ((ad.idfs.length > 0) === (selected.idfs.length > 0))));
    }

    /**
     * unsubcribe all subscriptions on destroy
     */
    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}