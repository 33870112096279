import {Injectable, OnDestroy} from '@angular/core';
import {RestServiceAbstract} from '../../../abstracts/rest-service.abstract';
import {map, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ResponseSnakeModel} from '../../../models/response-snake.model';

@Injectable({
  providedIn: 'root',
})
export class AdsRestService extends RestServiceAbstract implements OnDestroy {
  protected ngUnsubscribe$: Subject<void> = new Subject<void>();

  getAds(search: string) {
    return this.get('/cms/admin/ads', {params: {search: search}})
    .pipe(takeUntil(this.ngUnsubscribe$),
        map(data => {
          return new ResponseSnakeModel(data);
        })
    );
  }


  getForDate(start, end) {
      return this.get('/cms/admin/ads/date', {params: {start: start, end: end}})
          .pipe(takeUntil(this.ngUnsubscribe$),
              map(data => {
                  return new ResponseSnakeModel(data);
              })
          );
  }

  getCurrentAds(idf: string) {
    return this.get('/cms/ads', {params: {idf: idf}})
    .pipe(takeUntil(this.ngUnsubscribe$),
        map(data => {
          return new ResponseSnakeModel(data);
        })
    );
  }

  saveAd(ad) {
    return this.post('/cms/admin/ads',  ad).pipe(takeUntil(this.ngUnsubscribe$),
        map(data => {
          return new ResponseSnakeModel(data);
        }));
  }

  deleteAd(id) {
    return this.delete('/cms/admin/ads/' + id, {id: id})
    .pipe(takeUntil(this.ngUnsubscribe$),
        map(data => {
          return new ResponseSnakeModel(data);
        })
    );
  }

  /**
   * Unsubscribe from all subscriptions.
   */
  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.ngUnsubscribe$.next();
    // This completes the subject properly.
    this.ngUnsubscribe$.complete();
  }

    getSupplierAds(s: string) {
        return this.get('/cms/admin/ads/supplier', {params: {search: s}})
            .pipe(takeUntil(this.ngUnsubscribe$),
                map(data => {
                    return new ResponseSnakeModel(data);
                })
            );
    }

    getSupplierAdsForDate(start, end) {
        return this.get('/cms/admin/ads/date/supplier', {params: {start: start, end: end}})
            .pipe(takeUntil(this.ngUnsubscribe$),
                map(data => {
                    return new ResponseSnakeModel(data);
                })
            );
    }
}
