import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SupplierReportComponent} from './supplier-report.component';
import {RouterModule} from '@angular/router';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {ADS_DATE_FORMAT} from '../../../core/models/date-format.model';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import { AdReportComponent } from './ad-report/ad-report.component';
import { SliderReportComponent } from './slider-report/slider-report.component';
import { AdvertorialReportComponent } from './advertorial-report/advertorial-report.component';
import {MatTabsModule} from '@angular/material/tabs';
import { DialogReportComponent } from './dialog-report/dialog-report.component';

export const routes = [
  {path: '', component: SupplierReportComponent, pathMatch: 'full'},
];
@NgModule({
  declarations: [
      SupplierReportComponent,
      AdReportComponent,
      SliderReportComponent,
      AdvertorialReportComponent,
      DialogReportComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
      MatFormFieldModule,
      MatDatepickerModule,
      ReactiveFormsModule,
      MatSelectModule,
      MatInputModule,
      MatTabsModule
  ],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: ADS_DATE_FORMAT},
  ]
})
export class SupplierReportModule { }
